<div
  (click)="$event.stopPropagation()"
  class="add-tl-trade-container"
>
  <div class="menu-heading">
    <h3 class="menu-title">
      Add <span class="symbol-name">{{ data.symbol }}</span> to Trading Log
    </h3>

    <button
      (click)="close()"
      mat-icon-button
      class="popup-close-btn"
      tabindex="-1"
    >
      <mat-icon
        [svgIcon]="'close-modal-p'"
        class="close-modal-icon small-icon"
      >
      </mat-icon>
    </button>
  </div>

  <div class="block">
    <div class="block-title">What to add</div>
    <mat-button-toggle-group
      [(ngModel)]="selectedTransactionType"
      hideSingleSelectionIndicator="true"
      name="Add to trading log options"
      aria-label="Add to trading log options"
    >
      <mat-button-toggle
        [class.active]="selectedTransactionType === option"
        [value]="option"
        [disabled]="!canSwitchTransactionType && selectedTransactionType !== option"
        (click)="$event.stopPropagation()"
        (change)="onTransactionTypeChange()"
        *ngFor="let option of allowedTransactionTypes"
        disableRipple="true"
      >
        {{ tradingLogTransactionTypesFullNames[option] }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div
    *ngIf="selectedTransactionType ? showFieldsMap[selectedTransactionType] : false"
    class="block"
  >
    <div class="block-info">
      <div class="block-info-item">
        <h3 class="block-info-item-label readonly">Expiration</h3>
        <p class="block-info-item-text">{{ data.expiration | date: 'MMM d, yyyy' }}</p>
      </div>
      <div class="block-info-item">
        <h3 class="block-info-item-label readonly">Strike</h3>
        <p class="block-info-item-text">{{ data.strike | number: '1.2-2' }}</p>
      </div>
    </div>
  </div>

  <div class="block">
    <div class="block-info">
      <div class="block-info-item">
        <h3 class="block-info-item-label readonly">
          {{ selectedTransactionType && showFieldsMap[selectedTransactionType] ? 'Contracts' : 'Shares' }}
        </h3>
        <div class="form-group">
          <mat-form-field
            class="w-100"
            appearance="outline"
            subscriptSizing="dynamic"
          >
            <input
              [(ngModel)]="qty"
              [inputMask]="sharesInputMask"
              (click)="$event.target.select()"
              (keydown.enter)="
                $event.stopPropagation(); $event.preventDefault(); premiumInput.focus(); premiumInput.select()
              "
              (keydown.tab)="
                $event.stopPropagation(); $event.preventDefault(); premiumInput.focus(); premiumInput.select()
              "
              (keydown.arrowUp)="$event.stopPropagation(); $event.preventDefault(); onKeyDown('up', 'qty', 1, 999999)"
              (keydown.arrowDown)="
                $event.stopPropagation(); $event.preventDefault(); onKeyDown('down', 'qty', 1, 999999)
              "
              #qtyInput
              matInput
              appAutoFocusAndSelect
              autocomplete="off"
              type="text"
              placeholder=""
              step="1"
              min="0"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="block-info-item">
        <h3 class="block-info-item-label readonly">
          {{ selectedTransactionType && showFieldsMap[selectedTransactionType] ? 'Premium' : 'Price' }}
        </h3>
        <div class="form-group">
          <mat-form-field
            class="w-100"
            appearance="outline"
            subscriptSizing="dynamic"
          >
            <input
              [(ngModel)]="price"
              [inputMask]="premiumInputMask"
              (click)="$event.target.select()"
              (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); qtyInput.focus(); qtyInput.select()"
              (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); qtyInput.focus(); qtyInput.select()"
              (keydown.arrowUp)="
                $event.stopPropagation(); $event.preventDefault(); onKeyDown('up', 'price', 0.01, 999999)
              "
              (keydown.arrowDown)="
                $event.stopPropagation(); $event.preventDefault(); onKeyDown('down', 'price', 0.01, 999999)
              "
              #premiumInput
              matInput
              autocomplete="off"
              type="text"
              placeholder=""
              step="0.01"
              min="0"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div
      *ngIf="selectedTransactionType === transactionTypes.SellStock"
      class="block-comment-item-label readonly"
    >
      Use shares amount and price based on your entry order
    </div>
  </div>

  <div class="block">
    <div class="block-title">How to add</div>
    <mat-radio-group
      [(ngModel)]="currentGroupOptionValue"
      (change)="onGroupOptionChange()"
      color="primary"
      class="trades-radio-group"
      aria-label="Select an option"
      name="trades_group_type"
    >
      <mat-radio-button
        [value]="groupOptions.New"
        [checked]="currentGroupOptionValue === groupOptions.New"
        (click)="$event.stopPropagation()"
        class="custom-radio new-group"
      >
        Create a new <ng-container *ngIf="data?.symbol?.length > 0">{{ data?.symbol }}&nbsp;</ng-container>group
      </mat-radio-button>

      <!--  Block inside mat-radio-group - start -->
      <div
        *ngIf="currentGroupOptionValue === 'new'"
        class="new-group-settings"
      >
        <div
          *ngIf="accounts$ | async as accounts"
          class="from-group item"
        >
          <mat-select
            [(ngModel)]="selectedAccount"
            [disableRipple]="true"
            class="no-bg ellipsis p-l-6 p-r-1 select"
            tabindex="10"
            panelClass="add-tl-trade-select-panel middle-size max-height-170 fixed-width-290-for-mobile scroll-style"
            placeholder="Account"
          >
            <mat-option
              [value]="notDefinedId"
              class="ellipsis"
              >(not defined)</mat-option
            >
            <mat-option
              [value]="account.id"
              *ngFor="let account of accounts; trackBy: trackByFn"
            >
              {{ account.name }}
            </mat-option>
          </mat-select>
        </div>

        <div
          *ngIf="strategies$ | async as strategies"
          class="from-group item"
        >
          <mat-select
            [(ngModel)]="selectedStrategy"
            [disableRipple]="true"
            [disableOptionCentering]="true"
            class="no-bg ellipsis p-l-6 p-r-1 select"
            tabindex="10"
            panelClass="add-tl-trade-select-panel middle-size max-height-170 fixed-width-290-for-mobile scroll-style"
            placeholder="Strategy"
            panelWidth="auto"
          >
            <mat-option [value]="notDefinedId">(not defined)</mat-option>
            <mat-option
              [value]="strategy.id"
              *ngFor="let strategy of strategies; trackBy: trackByFn"
            >
              {{ strategy.name }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <!--  Block inside mat-radio-group - end -->

      <mat-radio-button
        [value]="groupOptions.Existing"
        (click)="$event.stopPropagation()"
        class="custom-radio existing-group"
      >
        Add to existing group
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <mat-divider
    *ngIf="currentGroupOptionValue === 'existing'"
    class="divider w-100"
  ></mat-divider>

  <div
    *ngIf="currentGroupOptionValue === 'existing'"
    class="block"
  >
    <div class="existing-groups">
      <!--  temporary hidden, do not remove  -->
      <!-- <div class="existing-groups-search form-group">
        <mat-form-field class="w-100" appearance="outline" subscriptSizing="dynamic">
          <input
            matInput
            type="text"
            autocomplete="off"
            spellcheck="false"
            maxlength="100"
            placeholder="Enter symbol"
            [(ngModel)]="searchString"
            (input)="searchSymbol()"
          >
          <mat-icon matPrefix [svgIcon]="'search-tool_icon'"></mat-icon>
          <mat-icon
            matSuffix
            *ngIf="searchString.length > 0"
            [svgIcon]="'close'"
            (click)="searchString = ''; searchSymbol();"
            class="clear-button"
          ></mat-icon>
        </mat-form-field>
      </div> -->

      <div class="existing-groups-list">
        <div
          *ngIf="filteredActiveGroups.length > 0 || filteredArchivedGroups.length > 0; else noData"
          class="trading-groups scroll-style"
        >
          <div
            [inViewportOptions]="{ threshold: [0], partial: true }"
            (inViewportAction)="onChangeVisibility($event, 'top')"
            class="top-indicator"
            inViewport
          ></div>

          <ng-container *ngIf="filteredActiveGroups.length > 0">
            <div
              [class.shadow-top]="!isGroupsListTopVisible"
              (click)="onChangeShowActiveTrades()"
              class="label expandable"
            >
              <button
                mat-icon-button
                class="btn-middle toggle-button close-open-item-btn component-btn default-bg"
              >
                <div
                  [class.close-item]="showActiveTrades"
                  class="arrow-wrapper"
                >
                  <div class="close-open-arrow"></div>
                </div>
              </button>
              <span>Active</span>
              <span class="groups-count">
                {{ filteredActiveGroups.length }}
              </span>
            </div>

            <section class="trading-group">
              <div
                *ngIf="showActiveTrades"
                class="trading-group-content"
              >
                <mat-radio-group
                  [(ngModel)]="selectedGroup"
                  color="primary"
                  aria-labelledby="trading-group-label"
                  class="trading-group-buttons"
                >
                  <mat-radio-button
                    [value]="group"
                    *ngFor="let group of filteredActiveGroups; trackBy: trackByFn"
                  >
                    <ng-container
                      [ngTemplateOutlet]="tradingGroupDetails"
                      [ngTemplateOutletContext]="{ group }"
                    ></ng-container>
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </section>
          </ng-container>

          <ng-container *ngIf="filteredArchivedGroups.length > 0">
            <!-- show top-shadow for this sticky label if "active-trades" section is hidden  -->
            <div
              [class.shadow-top]="!isGroupsListTopVisible && filteredActiveGroups.length === 0"
              (click)="onChangeShowArchivedTrades()"
              class="label expandable"
            >
              <button
                mat-icon-button
                class="btn-middle toggle-button close-open-item-btn component-btn default-bg"
              >
                <div
                  [class.close-item]="showArchivedTrades"
                  class="arrow-wrapper"
                >
                  <div class="close-open-arrow"></div>
                </div>
              </button>
              <span>Archived</span>
              <span class="groups-count">
                {{ filteredArchivedGroups.length }}
              </span>
            </div>

            <section class="trading-group">
              <div
                *ngIf="showArchivedTrades"
                class="trading-group-content"
              >
                <mat-radio-group
                  [(ngModel)]="selectedGroup"
                  color="primary"
                  aria-labelledby="trading-group-label"
                  class="trading-group-buttons"
                >
                  <mat-radio-button
                    [value]="group"
                    *ngFor="let group of filteredArchivedGroups; trackBy: trackByFn"
                  >
                    <ng-container
                      [ngTemplateOutlet]="tradingGroupDetails"
                      [ngTemplateOutletContext]="{ group }"
                    ></ng-container>
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </section>
          </ng-container>

          <div
            [inViewportOptions]="{ threshold: [0], partial: true }"
            (inViewportAction)="onChangeVisibility($event, 'bottom')"
            class="bottom-indicator"
            inViewport
          ></div>
        </div>
        <div
          *ngIf="!isGroupsListBottomVisible"
          class="shadow-bottom"
        ></div>
      </div>
    </div>
  </div>

  <app-error-message *ngIf="isError"></app-error-message>

  <div
    [class.add-trade-mobile-custom-class]="currentGroupOptionValue === groupOptions.Existing"
    class="block action-buttons-container"
  >
    <button
      [disabled]="currentGroupOptionValue !== groupOptions.New && !selectedGroup"
      (click)="addNewTransaction()"
      mat-button
      class="main-bg button action-btn"
    >
      Add Trade
    </button>
  </div>
</div>

<ng-template #noData>
  <div class="no-data-wrapper">
    <div class="no-data-image"></div>
    <div class="no-data-title">We could’t find any results</div>

    <!--  temporary hidden, do not remove  -->
    <!--  <div class="no-data-text">Change your search terms and try again</div>  -->
  </div>
</ng-template>

<ng-template
  #tradingGroupDetails
  let-group="group"
>
  <div class="trading-group-details">
    <div class="details-summary">
      <div class="symbol-container">
        <span
          [class.text-sm]="group.symbol.length > 5"
          [matTooltip]="group.symbol"
          [matTooltipDisabled]="group.symbol.length <= 23"
          class="symbol"
        >
          {{ group.symbol }}
        </span>
      </div>
      <div class="date-container">
        <ng-container *ngIf="group.min_date && group.min_date === group.max_date">
          <span class="date date-start">{{ group.min_date | date: 'MMM d, yyyy' }}</span>
        </ng-container>
        <ng-container *ngIf="group.min_date && group.min_date !== group.max_date">
          <span class="date date-start">{{ group.min_date | date: 'MMM d, yyyy' }}</span>
          <span class="date date-end">{{ group.max_date | date: 'MMM d, yyyy' }}</span>
        </ng-container>
      </div>
      <div class="user-info">
        <span
          [matTooltip]="group.account_name"
          [matTooltipDisabled]="group.account_name.length <= 12"
          class="account"
        >
          {{ group.account_name }}
        </span>
        <span
          [matTooltip]="group.strategy_name"
          [matTooltipDisabled]="group.strategy_name.length <= 12"
          class="strategy"
        >
          {{ group.strategy_name }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
